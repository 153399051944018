import * as React from 'react';
import PropTypes from 'prop-types';

//import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';

import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from '@mui/material/Fade';

function ScrollTop(props) {
    const { children } = props;
    const trigger = useScrollTrigger({
	disableHysteresis: true
    });

    const handleClick = (event) => {
	window.scrollTo({
	    top: 0,
	    behavior: 'smooth'
	});
    };

    return (
	<Fade in={trigger}>
	    <Box
		onClick={handleClick}
		role="presentation"
		sx={{ position: 'fixed', bottom: 16, right: 16 }}
	    >
		{children}
	    </Box>
	</Fade>
    );
}

ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
};

export default function BackToTop(props) {
    return (
	<React.Fragment>
	    <ScrollTop {...props}>
		<Fab size="small" aria-label="scroll back to top">
		    <KeyboardArrowUpIcon />
		</Fab>
	    </ScrollTop>
	</React.Fragment>
    );
}
