import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import Settings from './Settings';
import Loyalty from './Loyalty';
import Login from './Login';
import ChannelInfo from './ChannelInfo';
import AutoCounters from './AutoCounters';

function App (){
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(
	() =>
	createTheme({
	    spacing: 3,
            palette: {
		primary: {
		    main: blue[500],
		},
		secondary: {
		    main: '#f44336',
		},
		mode: prefersDarkMode ? 'dark' : 'light',
            },
	    components: {
		// Name of the component
		MuiInputBase: {
		    defaultProps: {
			// The props to change the default for.
			multiline: true, // No more ripple, on the whole application 💣!
		    },
		},
	    }
	}),
	[prefersDarkMode],
    );

    return (
	<ThemeProvider theme={theme}>
	    <CssBaseline />
	    <Routes>
		<Route path="/login" element={<Login />} />
		<Route path="/settings" element={<Settings />} />
		<Route path="/loyalty" element={<Loyalty />} />
		<Route path="/channelInfo" element={<ChannelInfo />} />
		<Route path="/autoCounters" element={<AutoCounters />} />
		<Route path="*" element={<Navigate to="/login" replace />} />	
	    </Routes>
	</ThemeProvider>
    );
}

export default App;

/*import React, {Component} from 'react';
  import { HashRouter, Routes, Route } from "react-router-dom";
  import Settings from './Settings';
  import Login from './Login';

  class App extends Component{
  render() {
  return (
  <HashRouter>
  </HashRouter>
  );
  }
  }

  export default App;*/
