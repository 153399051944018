import React, {Component} from 'react';
import { AutoForm } from 'uniforms-mui';
import { HiddenField, SubmitField, AutoField, ErrorsField } from 'uniforms-mui';
import { bridge as schema } from './schema-channelinfo.jsx';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Container from '@mui/material/Container';
import axios from 'axios';
import { Navigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import BackToTop from './custom/BackToTop';
import Header from './custom/Header';

class ChannelInfo extends Component{
    
    constructor(props) {
        super(props);
	const channelName = sessionStorage.getItem('channelName');
	this.state = {
	    channelData: null,
	    showSuccess: false,
	    showError: false,
	    channelName: channelName,
	    pics: {},
	    displayNames: {},
	    inProgress: false
	};
    }

    getParams(){
	const channelName = sessionStorage.getItem('channelName');
	const accessToken = sessionStorage.getItem('accessToken');
	if(channelName && accessToken){
	    return {
		channelName: channelName,
		accessToken: accessToken
	    };
	}else{
	    return null;
	}
    }
    
    componentDidMount(){
	const params = this.getParams();
	if(params != null){
	    const channelName = params.channelName;
	    const accessToken = params.accessToken;
	    this.loadChannelInfo(channelName, accessToken);
	}
    }

    saveOption(newChannel){
	const modedChannelsStr = sessionStorage.getItem("modedChannels");
	const modedChannels = JSON.parse(modedChannelsStr);
	const res = modedChannels.find((channel) => channel.value === newChannel.toLowerCase());
	if(!res){
	    modedChannels.push({value: newChannel, label: newChannel.toLowerCase()});
	    sessionStorage.setItem("modedChannels", JSON.stringify(modedChannels));
	}
    }

    loadChannelInfo(channelName, that){
	var channelData = null;
	const me = this || that;
	const userName = sessionStorage.getItem('userName');
	this.setState({inProgress: true});
	const accessToken = sessionStorage.getItem('accessToken');
	axios.get('https://api.mycroft.gg/channelInfo?channelName='
		  +channelName+'&userName='+userName,
		  {
		      headers: {
			  accessToken: accessToken
		      }
		  }).then((response)=>{

		      channelData = response.data;
		      if(channelData.error){
			  me.setState({error: channelData.error});
			  return;
		      }else{
			  me.setState({error: false});
		      }


		      me.setState({channelData: channelData});
		      me.saveOption(channelName);
		      const modedChannelsStr = sessionStorage.getItem("modedChannels");
		      const modedChannels = JSON.parse(modedChannelsStr);
		      const modedChannelNames = modedChannels.map((ch)=>ch.value);
		      me.loadProfilePics([...modedChannelNames,channelName]);
		  }).catch((error)=>{
		      console.log("error");
		      console.log(error);
		      me.setState({error: "You're either not a mod for this channel or the channel is not using Mycroft."});
		  }).finally(()=>{
		      this.setState({inProgress: false});
		  });
    }

    async loadProfilePics(userLogins){
	const accessToken = sessionStorage.getItem('accessToken');
	const userLoginsParam = userLogins.map(login=> "login="+login).join("&");
	const res = await axios.get('https://api.twitch.tv/helix/users?'+userLoginsParam,{
	    headers:{
		"Authorization": 'Bearer '+accessToken,
		"Client-Id": "8x7z4vsc7wogupf9p5529cno66ti09"
	    }
	});
	const users = res.data.data;
	const pics = {};
	const displayNames = {};
	users.forEach(user=>{
	    pics[user.login] = user.profile_image_url;
	    displayNames[user.login] = user.display_name;
	});
	this.setState({pics: pics, displayNames: displayNames});
    }

    selectChannel(channelName, me){
	console.log("ChannelInfo.selectChannel() - "+channelName);
	sessionStorage.setItem("channelName", channelName);
	me.setState({ channelName: channelName});
	window.setTimeout(function(){
	    me.loadChannelInfo(channelName, me);
	},10);
    }
    
    render() {
	const params = this.getParams();
	if(!params){
	    return <Navigate to="./login" replace={true} />;
	}else{
	    const modedChannelsStr = sessionStorage.getItem("modedChannels");
	    const modedChannels = JSON.parse(modedChannelsStr);
	    const me = this;
	    const onSelect = function(event){
		me.selectChannel(event.target.value, me);
		return true;
	    };
	    if(this.state.inProgress){
		return (
		    <Container maxWidth="md">
			
			<Header channelName={this.state.channelName}
				displayNames={this.state.displayNames}
				pic={this.state.pics[this.state.channelName]}
				modedChannels={modedChannels}
				onSelect={onSelect}
				pageName={"Channel Information"}
			/>
			<Box>
			    <CircularProgress />
			</Box>
		    </Container>		    
		);
	    }

	    return (
		this.state.error &&
		    <Container maxWidth="md">
			
			<Header channelName={this.state.channelName}
				displayNames={this.state.displayNames}
				pic={this.state.pics[this.state.channelName]}
				modedChannels={modedChannels}
				onSelect={onSelect}
				pageName={"Channel Information"}
			/>
			<Alert severity="error">
			    {this.state.error}
			</Alert>
		    </Container>
	    ) ||
		( !this.state.error &&
		  <Container maxWidth="md">
		      <Header channelName={this.state.channelName}
			      displayNames={this.state.displayNames}
			      pic={this.state.pics[this.state.channelName]}
			      modedChannels={modedChannels}
			      onSelect={onSelect}
			      pageName={"Channel Information"}
		      />
		      
		      <AutoForm
			  schema={schema}
			  model={this.state.channelData}
			  onSubmit={(model: any) => this.onSubmit(model)}>

			  <br />
			  <HiddenField name="channel" />
			  <Stack spacing={4}>
			      <AutoField name="info" multiline />
			      <ErrorsField />
			      <SubmitField value="Save"/>
			      <BackToTop children={true} />
			  </Stack>
		      </AutoForm>


		      <Grid item>
			  <Snackbar open={this.state.showSuccess} autoHideDuration={3000}>
			      <Alert severity="success">
				  Changes saved successfuly
			      </Alert>
			  </Snackbar>

			  <Snackbar open={this.state.showError} autoHideDuration={3000}>
			      <Alert severity="error">
				  Error saving changes
			      </Alert>
			  </Snackbar>
		      </Grid>
		  </Container>
		);
	}
    }

    onSubmit(changedModel){
	const model = Object.assign({}, changedModel);
	const accessToken = sessionStorage.getItem('accessToken');
	const channelName = sessionStorage.getItem('channelName');
	const userName = sessionStorage.getItem('userName');	

	const me = this;
	this.setState({inProgress: true});
	axios.put('https://api.mycroft.gg/channelInfo?channelName='+channelName+'&userName='+userName, model, {
	    headers: {
		accessToken: accessToken
	    }
	}).then((response)=>{
	    //console.log(response);
	    if(response.status === 200){
		me.setState({
		    showSuccess: true,
		    showError: false
		});
		window.setTimeout(()=>{
		    me.setState({
			showSuccess: false,
			showError: false
		    });
		}, 3000);
	    }
	}).catch((error)=>{
	    me.setState({
		showError: true,
		showSuccess: false
	    });
	    window.setTimeout(()=>{
		me.setState({
		    showSuccess: false,
		    showError: false
		});
	    }, 3000);	    
	}).finally(()=>{
	    this.setState({inProgress: false});
	    this.loadChannelInfo(channelName, me);
	});
    }
}

export default ChannelInfo;
