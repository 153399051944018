import Ajv from 'ajv';
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';
const ajv = new Ajv({ allErrors: true, useDefaults: true, $data: true });
ajv.addKeyword('uniforms');

const schema = {
    title: 'Loyality',
    type: 'object',
    properties: {
	channel: { type: 'string', title: 'Channel Name', readOnly: true },
	accumulationRate: { type: 'number', title: 'Point Accumulation Rate (per minute)', default: 10},
	subMultiplier: { type: 'number', title: 'Accumulation Multiplier for Subs', default: 3},
	loyaltyName: { type: 'string', title: 'Points Currency Name (plural)', default: 'points'}
    }
};

function createValidator(schema: object) {
    const validator = ajv.compile(schema);

    return (model: object) => {
	validator(model);
	console.log(validator.errors);
	return validator.errors?.length ? { details: validator.errors } : null;
    };
}

const schemaValidator = createValidator(schema);

export const bridge = new JSONSchemaBridge(schema, schemaValidator);
