import Ajv from 'ajv';
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';
const ajv = new Ajv({ allErrors: true, useDefaults: true, $data: true });
ajv.addKeyword('uniforms');

const schema = {
    title: 'Channel Info',
    type: 'object',
    properties: {
	channel: { type: 'string', title: 'Channel Name', readOnly: true },
	info: { type: 'string', title: 'Channel Information'}
    }
};

function createValidator(schema: object) {
    const validator = ajv.compile(schema);

    return (model: object) => {
	validator(model);
	console.log(validator.errors);
	return validator.errors?.length ? { details: validator.errors } : null;
    };
}

const schemaValidator = createValidator(schema);

export const bridge = new JSONSchemaBridge(schema, schemaValidator);
