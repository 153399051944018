import React, {Component} from 'react';
import { AutoForm } from 'uniforms-mui';
import { HiddenField, SubmitField, AutoField, ErrorsField } from 'uniforms-mui';
import { bridge as schema } from './schema/settings.jsx';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Container from '@mui/material/Container';
import axios from 'axios';
import { Navigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import DataObjectIcon from '@mui/icons-material/DataObject';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ListField } from 'uniforms-mui';
import { ListItemField } from 'uniforms-mui';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import CollapsibleField from './custom/CollapsibleField';
import BackToTop from './custom/BackToTop';
import Header from './custom/Header';
import Avatar from '@mui/material/Avatar';

class Settings extends Component{
    
    constructor(props) {
        super(props);
	const channelName = sessionStorage.getItem('channelName');
	this.state = {
	    channelData: null,
	    streamers: [],
	    showSuccess: false,
	    showError: false,
	    channelName: channelName,
	    pics: {},
	    displayNames: {},
	    userIds: {},
	    logins: {},
	    openAddStreamer: false,
	    openAddCommand: false,
	    addStreamerError: false,
	    addStreamerHelperText: null,
	    addCommandError: false,
	    addCommandHelperText: null,
	    addCommandValueError: false,
	    addCommandValueHelperText: null,
	    inProgress: false
	};
    }

    getParams(){
	const channelName = sessionStorage.getItem('channelName');
	const accessToken = sessionStorage.getItem('accessToken');
	if(channelName && accessToken){
	    return {
		channelName: channelName,
		accessToken: accessToken
	    };
	}else{
	    return null;
	}
    }
    
    componentDidMount(){
	const params = this.getParams();
	if(params != null){
	    const channelName = params.channelName;
	    const accessToken = params.accessToken;
	    this.loadSettings(channelName, accessToken);
	}
    }

    saveOption(newChannel){
	const modedChannelsStr = sessionStorage.getItem("modedChannels");
	const objAr = JSON.parse(modedChannelsStr);
	const list = Array.from(new Set(objAr.map(ch => ch.value)));
	const modedChannels = list.map(val => {
	    return{
		value: val,
		label: val
	    }
	});
	const res = modedChannels.find((channel) => channel.value === newChannel.toLowerCase());
	if(!res){
	    modedChannels.push({value: newChannel, label: newChannel.toLowerCase()});
	    sessionStorage.setItem("modedChannels", JSON.stringify(modedChannels));
	}
    }

    async loadSettings(channelName, that){
	this.setState({inProgress: true});
	var channelData = null;
	const me = this || that;
	const userName = sessionStorage.getItem('userName');
	const accessToken = sessionStorage.getItem('accessToken');
	axios.get('https://api.mycroft.gg/settings?channelName='
		  +channelName+'&userName='+userName,
		  {
		      headers: {
			  accessToken: accessToken
		      }
		  }).then(async (response)=>{

		      channelData = response.data;
		      if(channelData.error){
			  me.setState({error: channelData.error});
			  return;
		      }else{
			  me.setState({error: false});
		      }

		      channelData.commands = me.convertToArray(channelData.commands);
		      channelData.commandNames = channelData.commands.map(command => {
			  return { label: command.name, value: command.name };
		      });
		      channelData.customSO = me.convertToArray(channelData.customSO);
		      channelData.counters = me.convertToArray(channelData.counters);
		      channelData.timers = me.decodeTimers(channelData.timers);

		      me.setState({channelData: channelData, streamers: channelData.streamers});
		      me.saveOption(channelName);
		      const modedChannelsStr = sessionStorage.getItem("modedChannels");
		      const modedChannels = JSON.parse(modedChannelsStr);
		      const modedChannelNames = modedChannels.map((ch)=>ch.value);
		      const userNames = [...modedChannelNames,channelName];
		      const streamerIds = Array.from(new Set(channelData.streamers.map(name=> name.trim().toLowerCase().replace("@",""))));
		      const chunks = [];
		      const chunkSize = 100;
		      console.log(streamerIds);
		      for(let i=0;i<streamerIds.length;i += chunkSize){
			  chunks.push(streamerIds.slice(i,i+chunkSize));
		      }

		      const pics = {};
		      const displayNames = {};
		      const logins = {};
		      for(let chunk of chunks){
			  console.log(chunk);
			  const res = await me.loadProfilePics(chunk, userNames);
			  Object.assign(pics, res.pics);
			  Object.assign(displayNames, res.displayNames);
			  Object.assign(logins, res.logins);
		      }
		      this.setState({pics: pics, displayNames: displayNames, logins: logins, streamers: channelData.streamers});
		  }).catch((error)=>{
		      console.log("error");
		      console.log(error);
		      me.setState({error: "You're either not a mod for this channel or the channel is not using Mycroft."});
		  }).finally(()=> {
		      this.setState({inProgress: false});
		  });
    }

    async loadProfilePics(streamerIds, userNames){
	const pics = this.state.pics;
	const userIds = this.state.userIds;	
	const displayNames = this.state.displayNames;
	const logins = this.state.logins;
	const ids = streamerIds.filter(id => !pics[id]);
	if(!ids.length && !userNames.length)
	    return;
	const accessToken = sessionStorage.getItem('accessToken');
	const userLoginsParam = userNames.map(name=> "login="+name.trim().toLowerCase().replace("@","").replaceAll(" ",""));
	const userIdsParam = [];
	for(let userId of ids){
	    if(!isNaN(userId)){
		userIdsParam.push("id="+userId);
	    }else{
		userLoginsParam.push("login="+userId);
	    }
	}
	const paramsAr = Array.from(new Set([...userLoginsParam, ...userIdsParam]));
	const users = [];
	while(paramsAr.length !== 0){
	    const tempAr = paramsAr.splice(0,99);
	    const params = tempAr.join("&");
	    const res = await axios.get('https://api.twitch.tv/helix/users?'+params,{
		headers:{
		    "Authorization": 'Bearer '+accessToken,
		    "Client-Id": "8x7z4vsc7wogupf9p5529cno66ti09"
		}
	    });
	    users.push(...res.data.data);
	}
	
	users.forEach(user=>{
	    pics[user.id] = user.profile_image_url;
	    pics[user.login] = user.profile_image_url;
	    displayNames[user.id] = user.display_name;
	    displayNames[user.login] = user.display_name;
	    userIds[user.login] = user.id.toString();
	    logins[user.login] = user.id;
	});
	return {
	    logins: logins,
	    pics: pics,
	    displayNames: displayNames,
	    userIds: userIds
	};
    }

    selectChannel(channelName, me){
	console.log("Settings.selectChannel() - "+channelName);
	sessionStorage.setItem("channelName", channelName);
	me.setState({ channelName: channelName});
	window.setTimeout(function(){
	    me.loadSettings(channelName, me);
	},10);
    }
    
    handleShortcut(intent){
	console.log(intent);
	if(intent === 'addcommand'){
	    this.setState({openAddCommand: true,
			   addCommandError: false,
			   addCommandHelperText: null,
			   addCommandValueError: false,
			   addCommandValueHelperText: null
			  });
	}else if(intent === 'addstreamer'){
	    this.setState({openAddStreamer: true, addStreamerError: false, addStreamerHelperText: null});
	}
    }

    handleAddStreamer(me){
	console.log(this.state.streamerName);
	const newStreamer = this.state.streamerName;
	if(newStreamer.trim().length === 0){
	    this.setState({
		addStreamerError: true,
		addStreamerHelperText: "Streamer name can't be empty"
	    });
	    return;
	}
	const existingStreamers = this.state.channelData.streamers;
	if(existingStreamers.map(st => st.toLowerCase()).includes(newStreamer.toLowerCase())){
	    this.setState({
		addStreamerError: true,
		addStreamerHelperText: "The streamer is already in the auto-shoutout list"
	    });
	    return;
	}
	me.setState({openAddStreamer: false});
	existingStreamers.push(newStreamer);
	this.onSubmit(this.state.channelData);
    }

    handleAddCommand(me){
	console.log(this.state.commandName+"="+this.state.commandValue);
	const newCommandName = this.state.commandName;
	if(!newCommandName || !newCommandName.startsWith("!")){
	    this.setState({
		addCommandError: true,
		addCommandHelperText: "Command name should start with !"
	    });
	    return;
	}
	if(newCommandName.length < 2){
	    this.setState({
		addCommandError: true,
		addCommandHelperText: "Command name should not be blank"
	    });
	    return;
	}
	const newCommandValue = this.state.commandValue;
	if(!newCommandValue || newCommandValue.trim().length === 0){
	    this.setState({
		addCommandValueError: true,
		addCommandValueHelperText: "Command value can't be empty"
	    });
	    return;
	}
	const existingCommands = this.state.channelData.commands;
	if(existingCommands.map(cmd=>cmd.name).includes(newCommandName)){
	    this.setState({
		addCommandError: true,
		addCommandHelperText: "Command with this name already exists."
	    });
	    return;
	}
	me.setState({openAddCommand: false});
	existingCommands.push({name: newCommandName, value: newCommandValue});
	this.onSubmit(this.state.channelData);
    }

    render() {
	const params = this.getParams();
	if(!params){
	    return <Navigate to="./login" replace={true} />;
	}else{
	    const modedChannelsStr = sessionStorage.getItem("modedChannels");
	    const modedChannels = JSON.parse(modedChannelsStr);
	    const me = this;
	    const onSelect = function(event){
		me.selectChannel(event.target.value, me);
		return true;
	    };
	    if(this.state.inProgress){
		return (
		    <Container maxWidth="md">
			

			<Header channelName={this.state.channelName}
				displayNames={this.state.displayNames}
				pic={this.state.pics[this.state.channelName]}
				modedChannels={modedChannels}
				onSelect={onSelect}
				pageName={"General"}
			/>
			<Box>
			    <CircularProgress />
			</Box>
		    </Container>		    
		);
	    }

	    const actions = [
		{ name: 'Add Streamer', intent: 'addstreamer', icon: <AirlineSeatReclineNormalIcon /> },
		{ name: 'Add Command', intent: 'addcommand', icon: <DataObjectIcon />}

	    ];
	    return (
		this.state.error &&
		    <Container maxWidth="md">
			
			<Header channelName={this.state.channelName}
				displayNames={this.state.displayNames}
				pic={this.state.pics[this.state.channelName]}
				modedChannels={modedChannels}
				onSelect={onSelect}
				pageName={"General"}
			/>
			<Alert severity="error">
			    {this.state.error}
         	</Alert>
		    		    <Grid container justifyContent="center">
			<Box sx={{ p: 5 }}>
			    <Button variant="outlined" href={"https://mycroft.gg/#/?id=how-to-get-mycroft"}>
				Get Mycroft for your channel
			</Button>
			</Box>
		    </Grid>
	                
		    </Container>
	    ) ||
		( !this.state.error &&
		  <Container maxWidth="md">
		      <Header channelName={this.state.channelName}
			      displayNames={this.state.displayNames}
			      pic={this.state.pics[this.state.channelName]}
			      modedChannels={modedChannels}
			      onSelect={onSelect}
			      pageName={"General"}
		      />
		      
		      <AutoForm
			  schema={schema}
			  model={this.state.channelData}
			  onSubmit={(model: any) => this.onSubmit(model)}>

			  <br />
			  <HiddenField name="channelName" />	
			  <Stack spacing={4}>
			      <Accordion>
				  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
				      Streamer Friends
				  </AccordionSummary>
				  <AutoField
				      component={Autocomplete}
				      multiple
				      limitTags={20}
				      id="tags-filled"
				      name="streamers"
				      onChange={async (event, value, reason, details)=>{
					  if(reason === "createOption"){
					      console.log("value:");
					      console.log(value);
					      const option = details.option
						    .replaceAll(" ","").replace("@","").trim().toLowerCase();
					      if(this.state.logins[option]){
						  const id = this.state.logins[option];
						  if(this.state.streamers.indexOf(id) !== -1){
						      console.log("User already in shoutout list:"+option+"="+id);
						      return;
						  }
					      }
					      const res = await me.loadProfilePics([],[option]);
					      const logins = res.logins;
					      const match = value.find(v => v.toString().toLowerCase() === option);
					      const index = value.indexOf(match);
					      if(index !== -1){
						  //replace user.login with user.id
						  value.splice(index, 1);
						  console.log(logins);
						  const addedId = logins[option];
						  console.log(option+"="+addedId);
						  value.push(addedId);
					      }
					  }
					  me.setState({streamers: value});
				      }}
				      value={this.state.streamers}
				      options={[]}
				      getOptionLabel={(option)=> option}
				      freeSolo
				      renderTags={(value, getTagProps) =>{
					  return value.map((option, index) => (
					      <Chip avatar={<Avatar src={this.state.pics[option.toLowerCase()]} />}
						    variant="outlined"
						    label={this.state.displayNames[option.toLowerCase()] || option} {...getTagProps({ index })} />
					  ));
				      }}
				      renderInput={(params) => (
					  <TextField
					      {...params}
					      variant="filled"
					      placeholder="Streamer Username"
					  />
				      )}
				  />
			      </Accordion>
			      <Accordion>
				  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
				      Custom Shoutouts
				  </AccordionSummary>
				  <ListField name='customSO' title="Custom Shoutouts">
				      <ListItemField name="$">
					  <AutoField name="name"/>
					  <AutoField name="value"
						     options={this.state.channelData? this.state.channelData.commandNames : []}/>
				      </ListItemField>
				  </ListField>
			      </Accordion>
			      <CollapsibleField name="commands" title="Commands"/>
			      <Accordion>
				  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
				      Repeating Commands
				  </AccordionSummary>
				  <ListField name='timers' title="Repeating Commands">
				      <ListItemField name="$">
					  <AutoField name="cmd"
						     options={this.state.channelData?
							      this.state.channelData.commandNames
							      : []}/>
					  <AutoField name="interval" />
				      </ListItemField>
				  </ListField>
			      </Accordion>
			      <CollapsibleField name="counters" title="Counters"/>
			      <CollapsibleField name="admins" title="Admin Users"/>
			      <CollapsibleField name="quotes" title="Quotes"/>
			      <CollapsibleField name="templates" title="Templates - Personalize Responses"/>
			      <CollapsibleField name="games.heist" title="Game - Heist"/>
			      <CollapsibleField name="games.hangman" title="Game - Hangman"/>
		  	      
			      <AutoField name="communityName" />
			      <AutoField name="autoSOCommand" />
			      <AutoField name="persona" />
			      <AutoField name="monitorClips" />
			      <AutoField name="banLurkbots" />
                              <AutoField name="showAdAlerts" />
			      
			      <ErrorsField />
			      <SubmitField value="Save"/>
			      <BackToTop children={true} />
			  </Stack>
		      </AutoForm>


		      <Grid item>
			  <Snackbar open={this.state.showSuccess} autoHideDuration={3000}>
			      <Alert severity="success">
				  Changes saved successfuly
			      </Alert>
			  </Snackbar>

			  <Snackbar open={this.state.showError} autoHideDuration={3000}>
			      <Alert severity="error">
				  Error saving changes
			      </Alert>
			  </Snackbar>
		      </Grid>

		      <Dialog open={this.state.openAddStreamer}>
			  <DialogTitle>Add Streamer</DialogTitle>
			  <DialogContent>
			      <DialogContentText>
				  Enter the username of the streamer to receieve auto-shoutouts.
			      </DialogContentText>
			      <TextField
				  autoFocus
				  margin="dense"
				  id="name"
				  fullWidth
				  variant="standard"
				  label="Streamer Name"
				  error={this.state.addStreamerError}
				  helperText={this.state.addStreamerHelperText}
				  onChange={(event)=>{
				      me.setState({streamerName:event.target.value});
				  }}
			      />
			  </DialogContent>
			  <DialogActions>
			      <Button onClick={()=> this.setState({openAddStreamer: false})}>Cancel</Button>
			      <Button onClick={()=>{this.handleAddStreamer(this)}}>
				  Add
			      </Button>
			  </DialogActions>
		      </Dialog>

		      <Dialog open={this.state.openAddCommand}>
			  <DialogTitle>Add Command</DialogTitle>
			  <DialogContent>
			      <DialogContentText>
				  Enter the command name(starting with !) and text.
			      </DialogContentText>
			      <TextField
				  autoFocus
				  margin="dense"
				  id="commandname"
				  label="Command name"
				  fullWidth
				  variant="standard"
				  error={this.state.addCommandError}
				  helperText={this.state.addCommandHelperText}
				  onChange={(event)=>{
				      me.setState({commandName:event.target.value});
				  }}
			      />
			      <TextField
				  autoFocus
				  margin="dense"
				  id="commandvalue"
				  label="Command value"
				  fullWidth
                                  multiline
				  variant="standard"
				  error={this.state.addCommandValueError}
				  helperText={this.state.addCommandValueHelperText}
				  onChange={(event)=>{
				      me.setState({commandValue:event.target.value});
				  }}
			      />
			  </DialogContent>
			  <DialogActions>
			      <Button onClick={()=> this.setState({openAddCommand: false})}>Cancel</Button>
			      <Button onClick={()=>{this.handleAddCommand(this)}}>
				  Add
			      </Button>
			  </DialogActions>
		      </Dialog>

		      <SpeedDial
			  ariaLabel="Shortcuts"
			  sx={{ position: 'absolute', bottom: 16, right: 16 }}
			  icon={<SpeedDialIcon />}
		      >
			  {actions.map((action) => (
			      <SpeedDialAction
				  key={action.name}
				  icon={action.icon}
				  tooltipTitle={action.name}
				  onClick={()=>{
				      me.handleShortcut(action.intent);
				  }}
			      />
			  ))}
		      </SpeedDial>		      
		  </Container>
		);
	}
    }

    onSubmit(changedModel){
	const model = Object.assign({}, changedModel);
	model.commands = this.convertToObject(model.commands);
	model.customSO = this.convertToObject(model.customSO);
	model.counters = this.convertToObject(model.counters);
	model.timers = this.encodeTimers(model.timers);
	model.streamers = this.state.streamers;

	const accessToken = sessionStorage.getItem('accessToken');
	const channelName = sessionStorage.getItem('channelName');
	const userName = sessionStorage.getItem('userName');	

	const me = this;
	this.setState({inProgress: true});
	axios.put('https://api.mycroft.gg/settings?channelName='+channelName+'&userName='+userName, model, {
	    headers: {
		accessToken: accessToken
	    }
	}).then((response)=>{
	    //console.log(response);
	    if(response.status === 200){
		me.setState({
		    showSuccess: true,
		    showError: false
		});
		window.setTimeout(()=>{
		    me.setState({
			showSuccess: false,
			showError: false
		    });
		}, 3000);
	    }
	}).catch((error)=>{
	    me.setState({
		showError: true,
		showSuccess: false
	    });
	    window.setTimeout(()=>{
		me.setState({
		    showSuccess: false,
		    showError: false
		});
	    }, 3000);	    
	}).finally(()=>{
	    this.setState({inProgress: false});
	    this.loadSettings(channelName, me);
	});
    }

    convertToArray(obj){
	const arr = [];
	for (var key in obj) {
	    if(obj.hasOwnProperty(key)){
		const val = obj[key];
		const newObj = {};
		newObj.name = key;
		newObj.value = val;
		arr.push(newObj);
	    }
	}
	return arr;
    }

    convertToObject(arr){
	const obj = {};
	for(var i=0;i<arr.length;i++){
	    const item = arr[i];
	    const name = item.name;
	    const value = item.value;
	    obj[name] = value;
	}
	return obj;
    }

    decodeTimers(timers){
	const arr = [];
	if(timers){
	    const keys = Object.keys(timers);
	    for (let key of keys) {
		const val = timers[key];
		const newObj = {};
		newObj.cmd = key;
		newObj.interval = val.interval;
		arr.push(newObj);
	    }
	}
	return arr;	
    }

    encodeTimers(timers){
	const obj = {};
	for(let {cmd, interval} of timers){
	    obj[cmd] = {
		cmd: cmd,
		interval: interval
	    };
	}
	return obj;
    }    
}

export default Settings;
