import Ajv from 'ajv';
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';
import { LongTextField } from 'uniforms-mui';
const ajv = new Ajv({ allErrors: true, useDefaults: true, $data: true });
ajv.addKeyword('uniforms');

const schema = {
    title: 'Settings',
    type: 'object',
    properties: {
	channelName: { type: 'string', title: 'Channel Name', readOnly: true },
	commands: {
	    type: 'array',
	    items: {type: 'object', properties: {
		name: {type: 'string'},
		value: {
		    type: 'string',
		    uniforms: { component: LongTextField }
		}
	    }}
	},
	admins: {
	    type: 'array',
	    items: {
		type: 'string'
	    }
	},
	customSO: {
	    type: 'array',
	    items: {type: 'object', properties: {
		name: {type: 'string', title: 'Twitch Username'},
		value: {type: 'string', title: 'Command'}
	    }}
	},
	games: {
	    type: 'object',
	    properties: {
		heist: {
		    type: 'object',
		    properties: {
			minEntries: {
			    type: 'integer',
			    title: 'Minimum number of crew(including leader) required for heist',
			    minimum: 1,
			    default: 2
			},
			maxPoints: {
			    type: 'integer',
			    title: 'Max investment for user.',
			    minimum: 1,
			    default: 1000
			},
			winX: {
			    type: 'integer',
			    title: 'Winning multiplier. Survivors will get their investment times this number as winnings.',
			    minimum: 2,
			    default: 3
			},
			startDelay:{
			    type: 'integer',
			    title: 'Time(in minutes) to join the heist once it\'s started',
			    minimum: 1,
			    default: 2
			}
		    },
		    required: ['minEntries','maxPoints', 'startDelay', 'winX']
		},
		hangman: {
		    type: 'object',
		    properties: {
			maxIncorrectGuesses: {
			    type: 'integer',
			    title: 'Max incorrect guesses before game is over.',
			    default: 10
			},
			payout: {
			    type: 'integer',
			    title: 'Payout to each correct guesser after success.',
			    default: 1000
			}
		    },
		    required: ['maxIncorrectGuesses', 'payout']
		}
	    }
	},
	autoSOCommand: { type: 'string' , title: 'Auto SO Command'},
	communityName: { type: 'string' , title: 'Name of your community. This will be used as {community} variable in templates.', default: 'my community'},
	templates: {
	    type: 'object',
	    title: 'Template for various actions',
	    properties: {
		follow: {type: 'string', title: 'Follow response. Available variables: {follower}', default: 'Thank you for following {follower}! Welcome to my community!', maxLength: 400, uniforms: { component: LongTextField }},
		so: {type: 'string', title: 'Shoutout template. Available variables: {name}, {game}, {url}', default: 'Please go check out {name}. They were last seen playing "{game}", if you like what you see, give them a follow. You can find them at {url}', maxLength: 400, uniforms: { component: LongTextField }},
		cheer: {type: 'string', title: 'Response to cheer. Available variables: {cheerer}, {bits}', default: '{cheerer} has cheered with {bits} bits! Thanks @{cheerer}! Appreciate your generosity! VirtualHug @{cheerer} VirtualHug', maxLength: 400, uniforms: { component: LongTextField }},
		sub: {type: 'string', title: 'Response to subscription. Available variables: {subscriber}, {tier}, {totalmonths}', default: '{subscriber} just subscribed with tier {tier} subscription! Thank you for supporting my channel {subscriber}! VirtualHug VirtualHug VirtualHug ', maxLength: 400, uniforms: { component: LongTextField }},
		raid: {type: 'string', title: 'Response to a raid. Available variables: {raider}, {viewers}', default: 'Thank you @{raider} for the raid! VirtualHug', maxLength: 400, uniforms: { component: LongTextField }},
		gift: {type: 'string', title: 'Response to a gift. Available variables: {gifter}, {gifts}, {totalgifts}, {tier}', default: '{gifter} has gifted {gifts} tier {tier} subs to the community! Thanks @{gifter}! Appreciate your generosity! VirtualHug @{gifter} VirtualHug', maxLength: 400, uniforms: { component: LongTextField }},
		lurk: {type: 'string', title: 'Response to a !lurk. Available variables: {user}', default: '{user} is now lurking, they will be missed! We love our lurkers ...', maxLength: 400, uniforms: { component: LongTextField }},
		unlurk: {type: 'string', title: 'Response to a !unlurk. Available variables: {user}', default: '@{user} has returned from their lurk. Welcome back!', maxLength: 400, uniforms: { component: LongTextField }},
		adstart: {type: 'string', title: 'Ad started notification. Available variable: {duration}', default: 'Ad started! It will end in {duration} seconds. Subscribe for free with Twitch Prime to avoid ads and enjoy my emotes.', maxLength: 400, uniforms: { component: LongTextField }},
		adend: {type:'string', title: 'Ad ended notification.', default:'Welcome back from the ads!', maxLength: 400, uniforms: { component: LongTextField }},
		modso: {type:'string', title: 'Shoutout for mods.', maxLength: 400, uniforms: { component: LongTextField }}
	    }
	},
	persona: {type: 'string', title: 'Persona of Mycroft', maxLength: 100, default: 'be helpful'},
	monitorClips: {type: 'boolean', title: 'Monitor & post newly created clips.', default: true},
	banLurkbots: {type: 'boolean', title: 'Automatically ban lurk bots.', default: true},
	showAdAlerts: {type: 'boolean', title: 'Show ad start/end notifications.', default: true},
	quotes: {type: 'array', items: {type: 'string'}},
	counters: {
	    type: 'array',
	    items: {
		type: 'object',
		properties: {
		    name: {type: 'string'},
		    value: {type: 'integer'}
		}}},
	streamers: {
	    type: 'array',
	    title: 'Streamers for Auto-Shoutout',
	    items: {type: 'string'}
	},
	timers: {
	    type: 'array',
	    items: {
		type: 'object',
		properties: {
		    cmd: {
			type: 'string',
			pattern: '[!][a-zA-Z0-9]+',
			title:'Repeating Command'
		    },
		    interval: {
			type: 'integer',
			title: 'Repeat Interval in minutes',
			minimum: 1
		    }
		},
		required: ['cmd', 'interval']
	    }
	}
    }
};

function createValidator(schema: object) {
    const validator = ajv.compile(schema);

    return (model: object) => {
	validator(model);
	console.log(validator.errors);
	return validator.errors?.length ? { details: validator.errors } : null;
    };
}

const schemaValidator = createValidator(schema);

export const bridge = new JSONSchemaBridge(schema, schemaValidator);
