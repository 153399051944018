import axios from 'axios';

export function getParams(){
    const channelName = sessionStorage.getItem('channelName');
    const accessToken = sessionStorage.getItem('accessToken');
    if(channelName && accessToken){
	return {
	    channelName: channelName,
	    accessToken: accessToken
	};
    }else{
	return null;
    }
}

export function saveOption(newChannel){
    const modedChannelsStr = sessionStorage.getItem("modedChannels");
    const modedChannels = JSON.parse(modedChannelsStr);
    const res = modedChannels.find((channel) => channel.value === newChannel.toLowerCase());
    if(!res){
	modedChannels.push({value: newChannel, label: newChannel.toLowerCase()});
	sessionStorage.setItem("modedChannels", JSON.stringify(modedChannels));
    }
}

export async function loadProfilePics(userLogins, me){
    const accessToken = sessionStorage.getItem('accessToken');
    const userLoginsParam = userLogins.map(login=> "login="+login).join("&");
    const res = await axios.get('https://api.twitch.tv/helix/users?'+userLoginsParam,{
	headers:{
	    "Authorization": 'Bearer '+accessToken,
	    "Client-Id": "8x7z4vsc7wogupf9p5529cno66ti09"
	}
    });
    const users = res.data.data;
    const pics = {};
    const displayNames = {};
    users.forEach(user=>{
	pics[user.login] = user.profile_image_url;
	displayNames[user.login] = user.display_name;
    });
    me.setState({pics: pics, displayNames: displayNames});
}

export function selectChannel(channelName, me){
    console.log("ChannelInfo.selectChannel() - "+channelName);
    sessionStorage.setItem("channelName", channelName);
    me.setState({ channelName: channelName});
    window.setTimeout(function(){
	me.loadChannelInfo(channelName, me);
    },10);
}
