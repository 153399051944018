import React, {Component} from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import InputLabel from '@mui/material/InputLabel';
import { FormControl } from '@mui/material';
import Container from '@mui/material/Container';
//import Select from 'react-select';
import Select from '@mui/material/Select';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';



import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';

import Header from './custom/Header';

class Login extends Component{

    constructor(props) {
        super(props);
	this.state = {
	    modedChannels: null,
	    inProgress: false
	};
    }
    
    componentDidMount(){
	this.setParams();
    }
    
    setParams(){
	const hash = window.location.hash;
	if(hash){
	    const index = hash.indexOf("#access_token");
	    if(index !== -1){
		const regex = new RegExp(".#access_token=([^&]*)&.*");
		const matches = hash.match(regex);
		console.log(matches);
		if(matches.length === 2){
		    const accessToken = matches[1];
		    this.setUserName(accessToken);
		}else{
		    console.log("no access token");
		}
	    }
	}
    }

    async setUserName(accessToken){
	const me = this;
	const res = await axios.get('https://api.twitch.tv/helix/users',{
	    headers: {
		"Client-ID": "8x7z4vsc7wogupf9p5529cno66ti09",
		"Authorization": "Bearer "+accessToken
	    }
	});

	const data = res.data.data;
	console.log(data);
	if(data.length){
	    const user = data[0];
	    if(user){
		const userName = user.login;
		if(userName){
		    console.log("setUserName - "+userName);
		    const channelData = await this.getModdedChannels(userName,accessToken);
		    const channels = channelData.map((c)=> {
			return {value:c, label:c};
		    });
		    channels.push({value: userName, label: userName});
		    me.setState({
			modedChannels: channels
		    });
		    sessionStorage.setItem("accessToken", accessToken);
		    sessionStorage.setItem("userName", userName);
		    sessionStorage.setItem("modedChannels", JSON.stringify(channels));
		}
	    }
	}

    }

    async getModdedChannels(userName, accessToken){
	this.setState({inProgress: true});
	console.log('getModdedChannels(): userName='+userName);
	const res = await axios.get('https://api.mycroft.gg/mod/channels?userName='+userName,{
	    headers: {
		accessToken: accessToken
	    }
	});
	console.log(res);
	let channels = res.data.moddedChannels || [];
	console.log(channels);
	this.setState({inProgress: false});
	return channels;
    }

    getNewOptionData(newChannel){
	const modedChannelsStr = sessionStorage.getItem("modedChannels");
	const modedChannels = JSON.parse(modedChannelsStr);
	const res = modedChannels.find((channel) => channel.value === newChannel.toLowerCase());
	if(!res){
	    modedChannels.push({value: newChannel, label: newChannel.toLowerCase()});
	    sessionStorage.setItem("modedChannels", JSON.stringify(modedChannels));
	}
    }

    selectChannel(event){
	const channel = event.target.value;
	console.log("selectChannel - channel="+channel);
	sessionStorage.setItem("channelName", channel);
    }

    onContinue(){
	const channelName = sessionStorage.getItem("channelName");
	const accessToken = sessionStorage.getItem("accessToken");
	console.log("channelName="+channelName+", accessToken="+accessToken);
	if(channelName && accessToken){
	    window.location.href="#/settings";
	}
    }
    
    render() {
	const redirectURL = process.env.NODE_ENV === 'production' ? 'https://admin.mycroft.gg/' : 'http://localhost:3000/';
	const href = "https://id.twitch.tv/oauth2/authorize?response_type=token&client_id=8x7z4vsc7wogupf9p5529cno66ti09&scope=user:read:email&redirect_uri="+redirectURL;
	const me = this;
	const channelOptions = this.state.modedChannels;
	const showOptions = channelOptions != null;
	console.log(this.state.modedChannels);
	console.log("showOptions="+showOptions);
	console.log(redirectURL);
	return (
	    <Container maxWidth="md">

		<Header />
		{showOptions ? null :(
		    <Grid container justifyContent="center">
			<Box sx={{ p: 5 }}>
			    <Button variant="outlined" href={href}>
				Login with Twitch
			    </Button>
			</Box>
		    </Grid>
		) }

		{showOptions ? (
		    <Grid container justifyContent="center">
		    <FormControl fullWidth>
			<InputLabel> Select Channel </InputLabel>
			<Select label="Select Channel" onChange={me.selectChannel} options={channelOptions}>
			    {
				channelOptions.map(item=>{
				    return <MenuItem value={item.value}>{item.label}</MenuItem>;
				})
			    }
			</Select>
		    </FormControl>

			<Box sx={{ p: 5 }}>
	    		    <Button variant="outlined" onClick={me.onContinue}>
				Continue
			    </Button>
			</Box>
		    </Grid>

		) : null}

		{
		    this.state.inProgress?
			(
			    <Box sx={{ display: 'flex' }}>
				<CircularProgress />
			    </Box>
			): null
		}

	    </Container>
	);
    }
}

export default Login;
