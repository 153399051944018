import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {AutoField} from 'uniforms-mui';

export default function CollapsibleField({title, name}){
    return (
	<Accordion>
	    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
		{title}
	    </AccordionSummary>
	    <AutoField name={name} />
	</Accordion>
    );
}
