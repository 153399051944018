import Ajv from 'ajv';
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';
import { SelectField } from 'uniforms-mui';
const ajv = new Ajv({ allErrors: true, useDefaults: true, $data: true });
ajv.addKeyword('uniforms');

const schema = {
    title: 'Auto Incrementing Counters',
    type: 'object',
    properties: {
	channelName: { type: 'string', title: 'Channel Name', readOnly: true },
	triggers: {
	    type: 'object',
	    title: 'Auto Incrementing Counters',
	    properties:{
		bitDonation: {
		    type: 'object',
		    properties: {
			minBits: {type: 'integer', title: 'Minimum number of bits', minimum: 1},
			counter: { type: 'string', title: 'Counter Name', uniforms:{component: SelectField} },
			increment: {type: 'integer', minimum: 1, title: 'Increment per mininmum number of bits above'}
		    }
		},
		subscription:{
		    type: 'object',
		    properties: {
			counter: { type: 'string', title: 'Counter Name', uniforms:{component: SelectField}  },
			increment: {type: 'integer', minimum: 1, title: 'Increment per subscription'}
		    }
		},
		redeem:{
		    type: 'object',
		    properties: {
			counter: { type: 'string', title: 'Counter Name', uniforms:{component: SelectField} },
			redeemName: { type: 'string', title: 'Name of the redeem' },
			increment: {type: 'integer', minimum: 1, title: 'Increment per redeem'}
		    }
		},
		follow:{
		    type: 'object',
		    properties: {
			counter: { type: 'string', title: 'Counter Name', uniforms:{component: SelectField} },
			increment: {type: 'integer', minimum: 1, title: 'Increment per redeem'}
		    }
		}
	    }
	}
    }
};

function createValidator(schema: object) {
    const validator = ajv.compile(schema);

    return (model: object) => {
	validator(model);
	console.log(validator.errors);
	return validator.errors?.length ? { details: validator.errors } : null;
    };
}

const schemaValidator = createValidator(schema);

export const bridge = new JSONSchemaBridge(schema, schemaValidator);
